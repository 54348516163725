<template>
  <div class="box">
    <form action="/" class="headSearch">
      <van-search
        class="search_size"
        v-model="searchValue"
        placeholder="请输入搜索关键词"
        show-action
        label="款号"
        right-icon="scan"
        @click-right-icon="scan()"
        size="2rem"
      >
        <!-- @search="onSearch" -->
        <template #action>
          <!-- <div><a href="http://sao315.com/w/api/saoyisao?redirect_url=http://****.com/rl?code=1">扫一扫</a></div> -->
          <div class="search_btn" @click="onSearch(searchValue)">搜索</div>
        </template>
      </van-search>
    </form>

    <div class="tab_box" v-if="flag">
      <table class="tab" cellspacing="0">
        <thead>
          <tr style="height: 2.5rem">
            <th
              v-for="(item, index) in header"
              :key="index"
              class="headerTitle"
              :class="index < 4 ? 'getIndex' : ''"
              :style="index == 0 ? 'left:0px' : ''"
              ref="itemTitle"
            >
              <!-- :style="index <= 2 ? 'left: 0':''" -->
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in createData"
            :key="index"
            style="height: 2.1875rem"
          >
            <!-- v-for="(item, index) in createData.slice(11500,)"
            v-if="index >11599"
            v-for="index in 20" :key="index" -->


            <!-- 修改宽度后,需要保存并刷新页面才会更新 -->
            <!-- 仓库 -->
            <td style="width: 5rem; position: sticky; left: 0; z-index: 1" ref="itemA"  class="content" >
              {{ item.stock }}
            </td>
            <!-- SKU -->
            <td style="width: 5rem; position: sticky; z-index: 1" ref="itemB" class="content">
              {{ item.sku }}
            </td>
            <!-- 尺码 -->
            <td style="width: 3.5rem; position: sticky; z-index: 1" ref="itemC" class="content">{{ item.size_name }}</td>
            <!-- 库存数 -->
            <td style="width: 3rem; position: sticky; z-index: 1" ref="itemD" class="content" >
              {{ item.nums }}
            </td>
            <!-- 款号 -->
            <td style="width: 5.5rem; " class="content" >
              {{ item.goods_sn }}
            </td>
            <!-- 颜色 -->
            <td style="width: 6.25rem" class="content">
              {{ item.color_name }}
            </td>
            <!-- 商品名称 -->
            <td style="width: 6rem" class="content">
              {{ item.goods_name }}
            </td>
            <!-- 序号 -->
            <!-- <td style="width: 3rem; position: sticky; left: 0; z-index: 1" ref="itemA" class="content" > -->
            <td style="width: 3rem;"  class="content" >
              {{ index + 1 }}
            </td>
            <!--  <td class="content">{{item.orderNum}}</td>
          <td class="content">{{item.warehouse}}</td>
          <td class="content">{{item.typeNo}}</td>
          <td class="content">{{item.goodsName}}</td>
          <td class="content">{{item.col}}</td>
          <td class="content">{{item.size}}</td>
          <td class="content">{{item.onhand}}</td> -->
            <!-- 
          <td style="width: 3.125rem;" class="content">{{index}}</td>
          <td style="width: 6.25rem;" class="content">{{index}}</td>
          <td style="width: 6.25rem;" class="content">{{index}}</td>
          <td style="width: 6.25rem;" class="content">{{index}}</td>
          <td style="width: 6.25rem;" class="content">{{index}}</td>
          <td style="width: 6.25rem;" class="content">{{index}}</td>
          <td style="width: 6.25rem;" class="content">{{index}}</td> -->
          </tr>
        </tbody>
        <tfoot>
          <tr style="height: 2.5rem"  >
            <!-- <td
              class="text foot"
              style="font-weight: bold; position: sticky; left: 0; z-index: 1"
            >
              <div>合计</div>
              <div>{{ total }}</div>
            </td> -->
            <td class="foot" colspan="8" style="font-weight: bold; position: sticky; left: 0; z-index: 1" >
              <div class="statistics_Color_Style" >合计:{{ total }}，</div>  <div class="statistics_Color_Style" v-for="(item,index) in colorTotal" :key="index">{{item.color + ":" + item.num}}，</div>
            </td>
            <!-- <td class="sum foot" style="font-weight: bold">{{ total }}</td> -->
          </tr>
        </tfoot>
      </table>
    </div>
    <!-- 新加扫一扫 -->
    <httpsCodeReader
      ref="child"
      :scanShow="scanShow"
      @scanvalue="getScanvalue"
    ></httpsCodeReader>
    <!-- <div> -->
    <!-- <lisa-header title="扫描二维码"></lisa-header> -->
    <!-- <video ref="video" src="" id="video" class="video" autoplay></video>
      <div  class="tip">{{tipMsg}}</div>
    </div> -->
  </div>
</template>

<script>
// 手机调试工具
// import Vconsole from "vconsole";
import httpsCodeReader from "@/components/scan";
import { Search, Toast } from "vant";
// import {BrowserMultiformatReader} from '@zxing/library'
export default {
  components: {
    [Search.name]: Search,
    [Toast.name]: Toast,
    httpsCodeReader,
  },
  data() {
    return {
      searchValue: "", // 输入值
      header: [
        "仓库",
        "SKU",
        "尺码",
        "可用库存",
        "款号",
        "颜色",
        "商品名称",
        "序号",
      ],
      createData: [], //数据列表
      flag: false, // 占位
      total: 0, //总数
      // codeReader: new BrowserMultiformatReader(),
      tipMsg: "识别……",
      tipShow: false,
      scanShow: false,
      colorTotal:[],
    };
  },
  created() {
    this.tipShow = true;
    // this.openScan()

    // 开启手机调试工具
    // let vconsole = new Vconsole();
    // vconsole;
  },
  methods: {
    getScanvalue(scanvalue) {
      // alert(',值',callValue)
      console.log("值", scanvalue);
      console.log("报错?", scanvalue.text);
      this.searchValue = scanvalue.text || "";
      this.scanShow = scanvalue.scanShow;
    },
    // 扫码按钮
    async scan() {
      let code = await this.getScanCode();
        this.searchValue = code
        this.getDataList(code)
    },
    // 扫码结果
    getScanCode() {
      return new Promise((resolve) => {
        wx.scanQRCode({
          desc: 'scanQRCode desc',
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {          
              let scanResult = res.resultStr; // 当needResult 为 1 时，扫码返回的结果;
              let QRCodeArr = scanResult.split(',')
              scanResult = QRCodeArr[QRCodeArr.length-1]
              // that.searchValue = scanResult.length > 9 ? scanResult.slice(0,9) : scanResult;
              // 如果扫描结果字符串长度>9,截取到第9位
              let code = scanResult.length > 9 ? scanResult.slice(0,9) : scanResult;
              resolve(code)
            },
         });
       })
    },
    // openScan(){
    // console.log('+++++++++',new BrowserMultiformatReader());
    // const that = this;
    // console.log('88888999---',that.codeReader);
    // that.codeReader.getVideoInputDevices().then((videoInputDevices) => {
    //   that.tipShow = true;
    //   that.tipMsg = '调用摄像头...';
    //   console.log('videoInputDevices',videoInputDevices);
    // })
    // },
    // 表格显示不完全(固定前面三列,其余列表可左右滑动查看)
    getLeft() {
      // 获取表头元素
      let itemTitle = this.$refs.itemTitle;

      // 获取列表元素
      let items_A = this.$refs.itemA;
      let items_B = this.$refs.itemB;
      let items_C = this.$refs.itemC;

      /*  
        1)循环表头
        2)给表头2、3格 left动态赋值
        2-1) 表头第二格left = 第一格宽度
        2-2) 表头第三格left = 第一格宽度 + 第二格宽度
        每一格
      */
      for (let i = 1; i < 4; i++) {
        this.$refs.itemTitle[i].setAttribute(
          "style",
          `left:${
            i == 3
              ? itemTitle[0].offsetWidth +
                itemTitle[1].offsetWidth +
                itemTitle[2].offsetWidth
              : i == 2
              ? itemTitle[0].offsetWidth + itemTitle[1].offsetWidth
              : itemTitle[i - 1].offsetWidth
          }px`
        );
      }

      /* 
        1)循环列表数据长度
        2)给每列元素left动态赋值
        2-1) 第一列left = 第一列宽度
        2-2) 第二列left = 第一列宽度 + 第二列宽度
      */
      for (let j = 0; j < items_B.length; j++) {
        this.$refs.itemB[j].style.left = `${items_A[j].offsetWidth}px`;
        this.$refs.itemC[j].style.left = `${
          items_A[j].offsetWidth + items_B[j].offsetWidth
        }px`;
        this.$refs.itemD[j].style.left = `${
          items_A[j].offsetWidth +
          items_B[j].offsetWidth +
          items_C[j].offsetWidth
        }px`;
      }
    },
    // 搜索-输入款号
    onSearch(val) {
      this.getDataList(val);
    },

    // onCancel() {
    //   // Toast('取消');
    // },

    // 请求数据列表
    getDataList(val) {
      console.log('第二步val',val);
      this.$axios
        .get("https://order.wxh5r.cn:7878/api/xiaochong/stock", {
          params: {
            // goods_sn: TD0DXX004,
            goods_sn: val,
          },
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.flag = true;
            let list = res.data;
            this.createData = list;
            this.getColourNum(list)
            // 计算库存数总和
            let itemNum = 0;
            for (let i = 0; i < list.length; i++) {
              itemNum += Number(list[i].nums);
            }
            this.total = itemNum;

            setTimeout(() => {
              this.getLeft();
            }, 500);

            // let b = await getLeft()
            // console.log(b);
          } else {
            this.flag = false;
            Toast("暂时没有数据");
          }
        })
        .catch((err) => {
          console.log(err);
          // Toast(err.message)
        });
    },
    getColourNum(list){
      // 结果
      let colorTotal = [];

      // 将颜色进行归类,把颜色一致的push进对象数组
      let colourContainer = {};
      list.forEach( item =>{
        colourContainer[item.color_name] = colourContainer[item.color_name]  || [];
        colourContainer[item.color_name].push(item);
      });
      console.log('colourContainer',colourContainer);
      // 获取颜色系列
      let colourName = Object.keys(colourContainer);
      colourName.forEach( colourItem =>{
        let colorNum = 0;
        // 遍历归类好的颜色系列
        colourContainer[colourItem].forEach(item =>[
          // 将同一个色系数量相加,计算出总数
          colorNum += item.nums * 1
        ]);
        colorTotal.push({'color':colourItem , 'num':colorNum});
      })
      this.colorTotal = colorTotal;
      console.log('colorTotal',colorTotal);
    }
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>