<template>
  <div v-if="scanShow" class="page bgc-f4f4f4">
    <div class="video-show" >{{videoLength}}</div>
    <video id="video" ref="video" class="video vjs-fluid" autoplay ></video>
    <!-- <span class="change" @click="change"> -->
    <!-- <span class="change" >
      <i class="el-icon-refresh" ></i>
    </span> -->
  </div>
</template>
<script>
// import Vconsole from "vconsole";
import { BrowserMultiFormatReader } from "@zxing/library";
export default{
  name: "QrCodeSearch",
  props:['scanShow'],
  components: {},
  data() {
    return {
      loadingShow: false,
      // 这个就是从@zxing/library导出来的方法，new一个实例
      codeReader: null,
      // 这个，用来储存扫码得到的结果
      textContent: null,
      // 这个，就是当前调用的摄像头的索引，为什么是6，我会在后面说的
      num: 6,
      // 这个就是扫描到的摄像头的数量
      videoLength: "",
    };
  },

  created() {
    this.codeReader = new BrowserMultiFormatReader();
    // const vconsole = new Vconsole();
    // console.log('vconsole',vconsole);
    // vconsole;
    // setTimeout(() => {
    //   // 让我们调用这个方法尝试打开摄像头
    //   this.openScan();
    // }, 500);
  },
  // destroyed() {
  //   this.codeReader.stopContinuousDecode();
  //   this.codeReader.reset();
  // },
  methods:{
    async openScan() {
      // console.log('进入11111');
      
      console.log('进入2222',this.codeReader);
      this.codeReader.getVideoInputDevices().then(async (videoInputDevices) => {
        console.log('进入3333',videoInputDevices);
        
        // 记录一下扫描到的摄像头数量，这个videoInputDevices是个数组，里面有扫描到的摄像头数据
        // this.videoLength = videoInputDevices.length;
        this.videoInputDevicesArray = videoInputDevices
        // alert('摄像头数量',this.videoLength)
          // 这步我们来决定一下调用第几个摄像头，看到这个num没，这就是data里的
          // const firstDeviceId = videoInputDevices[that.num].deviceId;
          // 这调用另一个方法
          // that.decodeFromInputVideoFunc(
          //   firstDeviceId,
          //   videoInputDevices.length
          // );
          if (this.videoInputDevicesArray.length > 1) {
            console.log(this.videoInputDevicesArray[1].deviceId);
            this.deviceId = this.videoInputDevicesArray[1].deviceId;
          } else {
            this.deviceId = this.videoInputDevicesArray[0].deviceId;
          }
          this.decodeFromInputVideoFunc(this.deviceId)

      })
      // 失败回调，为什么这里的失败回调这么写，后面会说的
      .catch(err => {
          // this.num = 1;
          // this.openScan();
          // console.error(err);
          console.log('失败回调',err);
          setTimeout(()=>{
            let aa = false
            console.log('74777777-');
            this.$emit('scanvalue',aa);
          },5000)
        });
    },

    // 开始解码(这就是在openScan里调的另一个方法，传入想调用的摄像头id和摄像头数量)
    decodeFromInputVideoFunc(deviceId) {
      if (this.videoInputDevicesArray.length == 0) {
        alert('初始化摄像头失败', this.videoLength)
        return;
      }
      this.codeReader.reset();
      this.codeReader.decodeFromInputVideoDeviceContinuously(
        deviceId,"video",(result) => {
          if (result) {
            result.scanShow = false;
            // console.log("扫描成功6", result.text);
            this.$emit('scanvalue',result)
            // alert("扫描成功", result)
            window && window.getResultEvent(result);
              window?.parent?.Gikam?.toast('66',result.text);
              // 关闭扫码功能
              this.codeReader.reset();
              this.codeReader.stopContinuousDecode();
          }
        }
      )
    }
  }
}
</script>
<style scoped>
.video {
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
.video-show {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  height: 70vw;
  z-index: 2;
  background: linear-gradient(to left, #fff, #fff) left top no-repeat,
    linear-gradient(to bottom, #fff, #fff) left top no-repeat,
    linear-gradient(to left, #fff, #fff) right top no-repeat,
    linear-gradient(to bottom, #fff, #fff) right top no-repeat,
    linear-gradient(to left, #fff, #fff) left bottom no-repeat,
    linear-gradient(to bottom, #fff, #fff) left bottom no-repeat,
    linear-gradient(to left, #fff, #fff) right bottom no-repeat,
    linear-gradient(to left, #fff, #fff) right bottom no-repeat;
  background-size: 2px 20px, 20px 2px, 2px 20px, 20px 2px;
}
.tip {
  width: 10vw;
  height: 10vw;
  background-color: rgb(45, 236, 45);
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.bgc-f4f4f4 {
  background-color: #363636;
}

.page {
  position: relative;
  overflow-y: auto;
  position: relative;
  height: 100vh;
}
.change {
  z-index: 100;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-align: center;
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 60px;
  /* align-content: center; */
}
.el-icon-refresh {
  color: #000;
  font-size: 25px;
}
</style>